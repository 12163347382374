import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  FormControl,
  makeStyles,
  Button,
  Typography,
  Box,
  Divider,
  TextField,
  Menu,
  ButtonBase
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { curationActions } from '../../actions';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { BusinessUnitPropsCheckboxList } from "./../../components"
import FilterListIcon from '@material-ui/icons/FilterList';
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon } from '@material-ui/icons';

const filterOptions = createFilterOptions({
  limit: 100,
})

const useStyles = makeStyles((theme) => ({
  filterButton: {
    height: 51,
    borderRadius: 4,
    color: theme.palette.neutrals.low.dark,
    backgroundColor: theme.palette.neutrals.high.light,
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.neutrals.high.dark
    },
  },

  count: {
    marginRight: 3,
    padding: '3px 6px',
    backgroundColor: theme.palette.neutrals.low.lightest,
    fontSize: 11,
    color: theme.palette.neutrals.low.dark,
    borderRadius: 4,
  },

  titleDropBox: {
    padding: 24,
    color: theme.palette.neutrals.low.light
  }
}));

function CurationFilter({ selectedTab, stepsSelected, challengeSelected, occupationAreaSelected }) {
  const classes = useStyles();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters, paging } = useSelector(state => state.curation);
  const { occupationArea } = useSelector(state => state.businessUnitProps);
  const [anchorEl, setAnchorEl] = useState(null);
  const [countFilters, setCountFilters] = useState(filters.statusCode.filter(item => item.checked).length);

  useEffect(() => {
    if (occupationArea) {
      const countStatus = filters.statusCode.filter(item => item.checked && item.value !== 'SELECT_ALL');
      const countOccupationArea = occupationArea.filter(item => item.checked);
      const countChallenges = filters.challenges.filter(item => item.checked && item.value !== 'SELECT_ALL');
      setCountFilters((selectedTab === "LISTING" ? countStatus.length : 0) + countOccupationArea.length + countChallenges.length);
    }
  }, [filters, occupationArea, selectedTab])

  function handleClear() {
    dispatch(curationActions.clearFiltersNotStatus());
  };

  function handleSendFilters() {
    dispatch(curationActions.getStepFluxIdeas({
      ...filters,
      statusCode: [],
      stepsIdeas: stepsSelected,
      challenges: challengeSelected,
      occupationAreas: occupationAreaSelected,
      page: 1,
      limit: paging.limit,
      sort: paging.sort,
      query: paging.query
    }));
    handleClose()
  }

  function handleChangeChallenges(data, field) {
    if (data) {
      dispatch(curationActions.selectAllFilters(data.option, field));
    } else {
      dispatch(curationActions.clearCurationChallengesFilter());
    }
  }

  function handleChangeSteps(data, field) {
    if (data) {
      dispatch(curationActions.selectAllFilters(data.option, field));
    } else {
      dispatch(curationActions.clearCurationStepsFilter());
    }
  }

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (<>
    <ButtonBase
      onClick={handleOpen}
      className={classes.filterButton}
      width={countFilters > 0 ? 176 : 123}
    >
      <Box width="100%" display='flex' justifyContent="space-evenly" alignItems="center">
        {width > config.RESPONSIVE_MOBILE ? (
          <>
            <FilterListIcon />
            <Typography variant="subtitle2" style={{ padding: '0 13px' }}>{t('filtro')}</Typography>
          </>

        ) : (
          <FilterListIcon />
        )}
        {countFilters !== 0 && <span count={countFilters} className={classes.count}>{countFilters}</span>}
        <ArrowDropDown width="25%" />
      </Box>
    </ButtonBase>

    <Menu
      elevation={2}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <Typography variant="body2" className={classes.titleDropBox}>{t('filtrar_por')}</Typography>
      <Divider />
      <Box style={{ padding: 24, display: 'flex', flexDirection: 'column' }}>
        {/* {selectedTab === "LISTING" && (
          <FormControl style={{ marginBottom: 30, width: 280 }}>
            <Autocomplete
              multiple
              disableCloseOnSelect
							loadingText={t('carregando...')}
							noOptionsText={t('sem_opcoes')}
              id="checkboxes-status"
              options={filters.statusCode}
              loading={filters.statusCode.length === 0}
              getOptionLabel={(input) => input.label}
              value={filters.statusCode.filter(item => item.checked === true)}
              onChange={(e, items, options, details) => handleChange(details, "statusCode")}
              renderInput={(params) => <TextField {...params} label={t('status')} variant="filled" />}
              renderTags={(option) => {
                return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
              }}
              renderOption={(value) => (
                <li className={classes.flex}>
                  <Checkbox
                    color="primary"
                    checked={value.checked === true}
                  />
                  {value.label}
                </li>
              )}
            />
          </FormControl>
        )} */}

        {selectedTab === "LISTING" && (
          <FormControl style={{ marginBottom: 30, width: 280 }}>
            <Autocomplete
              multiple
              fullWidth
              disableCloseOnSelect
              loadingText={t('carregando...')}
              noOptionsText={t('sem_opcoes')}
              id="checkboxes-steps"
              options={filters.steps}
              loading={filters.steps.length === 0}
              getOptionLabel={(input) => input.label}
              value={filters.steps.filter(item => item.checked)}
              onChange={(_e, _items, _options, details) => handleChangeSteps(details, 'steps')}
              renderInput={(params) => <TextField {...params} label={t('etapas')} variant="filled" />}
              renderTags={(option) => {
                return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
              }}
              renderOption={(value) => (
                <li className={classes.flex}>
                  <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckboxIcon fontSize="small" />}
                    checked={value.checked}
                  />
                  {value.label}
                </li>
              )}
            />
          </FormControl>
        )}
        <FormControl style={{ marginBottom: 30, width: 280 }}>
          <Autocomplete
            multiple
            loadingText={t('carregando...')}
            noOptionsText={t('sem_opcoes')}
            filterOptions={filterOptions}
            disableCloseOnSelect
            id="checkboxes-challenge"
            name="2"
            options={filters.challenges}
            loading={filters.challenges.length === 0}
            getOptionLabel={(input) => input.label}
            value={filters.challenges.filter(item => item.checked === true)}
            onChange={(_e, _items, _options, details) => handleChangeChallenges(details, "challenges")}
            renderInput={(params) => <TextField {...params} label={t('desafio')} variant="filled" />}
            renderTags={(option) => {
              return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
            }}
            renderOption={(value) => (
              <li className={classes.flex}>
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckboxIcon fontSize="small" />}
                  checked={value.checked === true}
                />
                {value.label}
              </li>
            )}
          />
        </FormControl>

        <FormControl style={{ marginBottom: 30, width: 280 }} >
          <BusinessUnitPropsCheckboxList
            localField={false}
            businessUnitField={false}
          />
        </FormControl>
      </Box>

      <Box display='flex' justifyContent='end' style={{ padding: '0 24px 24px 0' }}>
        <Button
          color="primary"
          style={{ marginRight: 18 }}
          onClick={handleClear}
          variant="outlined">
          {t('limpar_filtro')}
        </Button>
        <Button
          color="primary"
          onClick={handleSendFilters}
          variant="contained"
          startIcon={<FilterListIcon />}
        >
          {t('filtrar')}
        </Button>
      </Box>
    </Menu>
  </>);
}

export { CurationFilter };
