import { articleConstants } from '../constants';

const initialState = {
  newArticle: { title: "", description: "", thumbnail: {}, banner: {}, authors: [], status: false, highlight: false },
  articles: [],
  authors: [],
  loading: false,
  articleDetails: { title: "", description: "", thumbnail: {}, banner: {}, authors: [], status: false, highlight: false },
  paging: {
    page: 1,
    sort: { "createdAt": -1 },
    limit: 10,
    searchText: ""
  }
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
  case articleConstants.SET_ARTICLE_HIGHLIGHT:
      return {
        ...state,
        newArticle: { ...state.newArticle, highlight: action.payload }
      }

  case articleConstants.SET_ARTICLE_HIGHLIGHT_EDIT:
      return {
        ...state,
        articleDetails: { ...state.articleDetails, highlight: action.payload }
      }

  case articleConstants.SET_ARTICLE_STATUS:
      return {
        ...state,
        newArticle: { ...state.newArticle, status: action.payload }
      }

  case articleConstants.SET_ARTICLE_STATUS_EDIT:
      return {
        ...state,
        articleDetails: { ...state.articleDetails, status: action.payload }
      }

   case articleConstants.SET_ARTICLE_AUTHORS:
      return {
        ...state,
        newArticle: { ...state.newArticle, authors: action.payload }
      }

   case articleConstants.SET_ARTICLE_AUTHORS_EDIT:
      return {
        ...state,
        articleDetails: { ...state.articleDetails, authors: action.payload }
      }

    case articleConstants.GET_ARTICLE_BY_ID_SUCCESS:
      return {
        ...state,
        articleDetails: action.payload
      }

    case articleConstants.GET_ARTICLES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case articleConstants.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload.data,
        loading: false,
        paging: {
          ...state.paging,
          page: action.payload.paging.page,
          sort: action.payload.paging.sort,
          limit: action.payload.paging.limit
        },
      };

    case articleConstants.GET_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case articleConstants.GET_MORE_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: [...state.articles, ...action.payload.data],
        loading: false,
        paging: {
          ...state.paging,
          page: action.payload.paging.page,
          sort: action.payload.paging.sort,
          limit: action.payload.paging.limit,
          searchText: action.payload.paging.searchText
        }
      };

    case articleConstants.GET_MORE_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case articleConstants.SET_ARTICLE_IMAGE:
      const { type, image, isEditImage  } = action.payload;
      const fieldImageEdit = isEditImage ? 'articleDetails' : 'newArticle';
      return {
        ...state,
        [fieldImageEdit]: {
          ...state[fieldImageEdit],
          [type]: image,
        }
      };

    case articleConstants.REMOVE_ARTICLE_IMAGE:
      const { type: imageType, edit } = action.payload;
      const fieldEdit = edit ? 'articleDetails' : 'newArticle';
      return {
        ...state,
        [fieldEdit]: {
          ...state[fieldEdit],
          [imageType]: {},
        }
      };

    case articleConstants.SET_IMAGE_TO_REMOVE_ARTICLE:
      const { type: imageTypeToRemove } = action.payload;
      return {
        ...state,
        newArticle: {
          ...state.newArticle,
          [imageTypeToRemove]: {},
        }
      };

    case articleConstants.SET_ARTICLE:
      const { article } = action;
      return {
        ...state,
        newArticle: { ...state.newArticle, description: article.description, title: article.title },
      };

    case articleConstants.SET_PROP_ARTICLE:
      const { value, name, isEdit } = action;
      const field = isEdit ? 'articleDetails' : 'newArticle';
      return {
        ...state,
        [field]: {
          ...state[field],
          [name]: value,
        },
      };

    case articleConstants.CREATE_ARTICLE_REQUEST:
      return state;

    case articleConstants.CREATE_ARTICLE_SUCCESS:
      return state;

    case articleConstants.CREATE_ARTICLE_FAILURE:
      return state;

    case articleConstants.CLEAR:
      return initialState;

    default:
      return state;
  }
};

export { articlesReducer };
