import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Hidden, DialogActions, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dialogCss } from './dialogCss';
import { LoadingButton } from '../Shared';
import { DialogAttachment } from './DialogAttachment';
import { useCreateIdeaController } from './hooks/useCreateIdeaController';
import config from '../../config';

export function CreateIdeaActions({ name, onSubmit }) {
  const { t } = useTranslation();
  const dialogClasses = dialogCss();
  const { previous, createDraft, ideaCreate, loading } = useCreateIdeaController();
  const { fromHistory, from } = useSelector(state => state.ideaCreateDialogs);
  const goBack = name === 'DIALOG_CLASSIFICATION' ? from : fromHistory.length > 0;

  return (
    <DialogActions className={clsx(dialogClasses.containerAction, dialogClasses.dialogActions)}>
      {['DIALOG_TITLE_DESCRIPTION'].includes(name) && (
        <DialogAttachment from="DIALOG_TITLE_DESCRIPTION" />
      )}

      {goBack && (
        <Button
          color="primary"
          onClick={previous}
          startIcon={<ArrowBackIcon />}
          className={dialogClasses.button}
        >
          {t('voltar')}
        </Button>
      )}

      {loading ? (
        <LoadingButton />
      ) : (
        <Box>
          <Hidden smDown>
            <Button
              className={dialogClasses.button}
              variant="outlined"
              color="primary"
              type="submit"
              onClick={createDraft}
            >
              {t('salvar_rascunho')}
            </Button>
          </Hidden>
          <Button
            className={dialogClasses.button}
            variant="contained"
            color="primary"
            style={{ marginLeft: 8 }}
            startIcon={<ArrowForwardIcon />}
            onClick={onSubmit}
            disabled={config.ALLOW_IDEIAS_WITHOUT_CHALLENGE === false && ideaCreate?.challenge?.name === 'Ideia Aberta'}
          >
            {t('avancar')}
          </Button>
        </Box>
      )}
    </DialogActions>
  );
}