import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import { tableCss } from './TableCss';
import { infraStructureActions } from './../../actions';
import { InfrastructureRow } from '../Infrastructure/InfrastructureRow';
import { useState } from 'react';
import { useEffect } from 'react';
import InfraStructureCollapseRow from '../Infrastructure/InfraStructureCollapseRow/InfraStructureCollapseRow';

function InfraestructureTable({ tabChosed }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedTab } = useSelector(state => state.infraStructure);
  const { infraStructures, paging, filters: { types, dateSort }, associations } = useSelector((state) => state.infraStructure);
  const tableClasses = tableCss({ text: t('linhas_por_pagina') });

  const columns = [
    { id: 'date', label: t('data_do_cadastro') },
    { id: 'name', label: t('nome_da_infraestrutura') },
    { id: 'type', label: t('tipo_da_infraestrutura') },
    tabChosed === 'links' && { id: 'locais_vinculados', label: t('locais_vinculados') },
    tabChosed === 'links' && { id: 'areas_vinculadas', label: t('areas_vinculadas') }
  ];

  function handleChangePage(_event, page) {
    let newPage = page + 1;
    tabChosed === "links" ? dispatch(infraStructureActions.getAllAssociations(newPage, paging.limit, dateSort, paging.query)) :
      dispatch(infraStructureActions.getInfraStructure({ page: newPage, sort: 'name', limit: paging.limit, types, dateSort }));
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    tabChosed === "links" ? dispatch(infraStructureActions.getAllAssociations(paging.page, limit, dateSort, paging.query)) :
      dispatch(infraStructureActions.getInfraStructure({
        page: paging.page,
        limit: limit,
        sort: 'name', types, dateSort,
      }));
  }

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer sx={{ position: 'relative' }}>
        <Table
          stickyHeader
          aria-label="infrastructure table"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} classes={{ root: tableClasses.tableCellHeader }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabChosed === 'links' ? associations.map((item) => (
              <InfraStructureCollapseRow key={item._id} row={item} />)) : infraStructures.filter(item => item.type === selectedTab).map((item) => (
                <InfrastructureRow key={item._id} row={item} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={paging.total}
        rowsPerPage={paging.limit}
        page={paging.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
        style={{ position: 'relative', zIndex: 10 }}
      />
    </Paper>
  );
}

export { InfraestructureTable };
