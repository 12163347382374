import { styled } from '@material-ui/core';
import {
  Card as MuiCard,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress
} from '@material-ui/core';

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.neutrals.high.main,
  border: `1px solid ${theme.palette.neutrals.high.medium}`
}));


export const DateAndTimeContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  color: theme.palette.neutrals.low.light
})); 


export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  width: '100%',
  height: 8,
  borderRadius: 5,
  margin: theme.spacing(0, 1),
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const Indicators = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(1.5, 0)
}));