import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';


import { Container, makeStyles, CircularProgress, Button, Card, Grid, Typography, CardHeader, CardContent, CardActions, CardMedia, Box, Avatar, useTheme } from '@material-ui/core';
import { articleActions } from '../../actions';
import { BackNavAdmin } from '../../components';
import { Edit, Person } from '@material-ui/icons';
import { history } from '../../helpers';
import { IdeaCss } from '../../components/Idea/IdeaCss';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  containerLoading: {
    marginTop: theme.spacing(30),
    display: 'flex',
    justifyContent: 'center',
  },
  media: {
    height: 203,
    backgroundColor: theme.palette.neutrals.low.light,
    borderRadius: 8,
  },
  card: {
    padding: '30px 20px'
  }
}));

function ArticleDetailPage() {
  const { id } = useParams();
  const classes = useStyles();
  const ideaClasses = IdeaCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    articleDetails,
  } = useSelector((state) => state.articlesReducer);

  useEffect(() => {
    dispatch(articleActions.getArticleById(id));
  }, [dispatch, id]);


  function handleClickEditButton(id) {
    history.push(`/article/edit/${id}`);
  }

  return (
    <>
      {(articleDetails._id !== id) ? (
        <>
          <BackNavAdmin title={t('carregando...')} pathname="/articles" />
          <Container maxWidth="lg" className={classes.containerLoading}>
            <CircularProgress />
          </Container>
        </>
      ) : (
        <>
          <BackNavAdmin title={'artigo'} pathname="/articles"><Button onClick={() => handleClickEditButton(id)} startIcon={<Edit />} variant="outlined" color="primary">{t('editar_artigo')}</Button></BackNavAdmin>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <CardMedia className={classes.media} image={articleDetails.banner?.url || imageCard} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Card className={classes.card}>
                  <CardActions>
                    <Typography style={{ fontSize: 20, fontWeight: 600 }}>{articleDetails.title}</Typography>
                  </CardActions>
                  <CardContent>
                    <Typography>{articleDetails.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card className={classes.card}>
                  <CardActions>
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>{t('autores').toLocaleUpperCase(0)}</Typography>
                  </CardActions>
                  <CardContent>
                    {articleDetails?.authors?.length > 0 && articleDetails?.authors?.map((contributor, index) => (
                      <Box display="flex" alignItems="center" marginBottom="1rem" key={index}>
                        {contributor.attachment?.url ? (
                          <Avatar
                            className={ideaClasses.contributorImage}
                            src={contributor.attachment?.url}
                          />
                        ) : (
                          <Avatar
                            className={ideaClasses.contributorImage}
                            style={{ backgroundColor: theme.palette.primary.light }}
                          >
                            <Person style={{ color: theme.palette.primary.main }} />
                          </Avatar>
                        )}
                        <Typography
                          variant="subtitle2"
                          className={ideaClasses.contributorName}
                          onClick={() => history.push(`/profile/${contributor._id}`)}
                        >
                          {contributor.name}
                        </Typography>
                      </Box>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}

export { ArticleDetailPage };
