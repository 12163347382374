import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const articlesService = {
  getArticles,
  createArticle,
  uploadArticleImage,
  getArticleById,
  deleteArticle,
  putArticle
};

function deleteArticle(id) {
  return axios
    .delete(`${config.URL_BASE}/articles/${id}`, requestHeaders())
    .then(handleResponse);
}

function getArticleById(id) {
  return axios
    .get(`${config.URL_BASE}/articles/${id}`, requestHeaders())
    .then(handleResponse);
}

function getArticles(page, sort, limit, searchText) {
  return axios
    .get(
      `${config.URL_BASE}/articles?page=${page}&sort=${JSON.stringify(
        sort
      )}&limit=${limit}&searchText=${searchText}`,
      requestHeaders()
    )
    .then(handleResponse);
}

function createArticle(article) {
  return axios
    .post(`${config.URL_BASE}/articles`, article, requestHeaders())
    .then(handleResponse);
}

function putArticle(article) {
  return axios
    .put(`${config.URL_BASE}/articles/${article._id}`, article, requestHeaders())
    .then(handleResponse);
}

function uploadArticleImage(type, file, id) {
  const data = new FormData();

  data.append(type, file?.file, file?.name);

  return axios.post(`${config.URL_BASE}/uploads/article/${id}`, data, requestHeaders());
};