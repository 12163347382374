import React, { useEffect } from 'react';
import { Container, makeStyles, Grid, Card } from '@material-ui/core';
import { BackNavAdmin, ArticleCreateCard, ArticleImageCard, ArticleProgressCard, ArticleAuthors, ArticleStatus } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  card: {
    marginBottom: 20,
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    top: '76px',
  },
}));

function ArticleNewPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    newArticle: { title, description, thumbnail, banner },
    newArticle,
    loading
  } = useSelector((state) => state.articlesReducer);


  const shouldDisable = (
    !!title?.length &&
    description?.length > 100
  );

  return (
    <div>
      <BackNavAdmin title="Novo Artigo"></BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <div className={classes.card}>
              <ArticleCreateCard
                title={title}
                description={description}
              ></ArticleCreateCard>
            </div>

            <Card className={classes.card}>
              <ArticleImageCard
                type="thumbnail"
                image={thumbnail}
              ></ArticleImageCard>
              <ArticleImageCard
                type="banner"
                image={banner}
              ></ArticleImageCard>
            </Card>

            <Card className={classes.card}>
              <ArticleAuthors />
            </Card>

            <Card className={classes.card}>
              <ArticleStatus />
            </Card>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fixed}>
              <ArticleProgressCard
                article={newArticle}
                loading={loading}
                shouldDisable={!shouldDisable}
              ></ArticleProgressCard>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export { ArticleNewPage };
