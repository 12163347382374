import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reportActions } from '../../../actions';
import { formatDate } from '../../../utils/date';
import config from '../../../config';
import moment from 'moment';

export function useReportFiltersController() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [splittedPathname, checked] = useState(history.location.pathname.split("/"), true);
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps);
  const { filters, paging, fieldValidation, loading, tab, report, requestParams } = useSelector(state => state.report);
  const stepFlux = config.SHOW_IDEAS_BY_STEP_FLUX;

  const ideaStatusReportType = stepFlux ? 'ideas-by-step' : splittedPathname[splittedPathname.length - 1];
  const financialReturnReportType = stepFlux ? 'ideas-financial-return-by-step' : splittedPathname[splittedPathname.length - 1];

 const reportTypeText = (() => {
    switch(splittedPathname[splittedPathname.length - 1]) {
      case 'ideas-status':
        return ideaStatusReportType;

      case 'ideas-financial-return':
        return financialReturnReportType;

      default:
        return splittedPathname[splittedPathname.length - 1];
    }
  })();

  const data = {
    challenge: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.challenges && filters.challenges.filter(item => item.checked).map(item => item.value),
    [stepFlux ? 'stepsIdeas' : 'statusCode']: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status && filters.status.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    personalizedClassifications: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.personalizedClassifications && filters.personalizedClassifications.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    businessUnits: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status && businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    startDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.startDate && formatDate(filters.startDate),
    endDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.endDate && formatDate(filters.endDate),
    bulWithoutUsers: filters.bulWithoutUsers,
    ideasUngrouped: filters.ideasUngrouped,
    ideaDescriptionOnCsv: filters.ideaDescriptionOnCsv,
    useConclusionDate: filters.useConclusionDate,
    ideasVisibilityInFeed: filters.ideasVisibilityInFeed,
    reportType: reportTypeText,
    
  }

  const dataColaboratorAccessMonth = {
    businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    monthYear: moment(filters.monthYear).format('MM/YYYY'),
    page: paging.page,
    limit: paging.limit,
    reportType: splittedPathname[splittedPathname.length - 1]
  };

  const dataIdeasWithEvaluatedSteps = {
    startDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.startDate && formatDate(filters.startDate),
    endDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.endDate && formatDate(filters.endDate),
    reportType: reportTypeText,
    page: paging.page,
    limit: paging.limit,
  };

  function handleFilter() {
    let params;

    if (splittedPathname[splittedPathname.length - 1] !== ('colaborator-access-by-month' && 'ideas-with-evaluated-steps')) {
      params = {
        ...data,
        searchText: filters.query,
      };
    } else if (splittedPathname[splittedPathname.length - 1] === 'ideas-with-evaluated-steps') {
      params = {
        ...dataIdeasWithEvaluatedSteps,
        searchText: filters.query,
      }
    } else {
      params = {
        ...dataColaboratorAccessMonth,
        searchText: filters.query,
      };
    }

    dispatch(reportActions.preFilter())

    if (tab === "table") {
      params = {
        ...params,
        page: 1,
        limit: paging.limit,
      }
      dispatch(reportActions.getReportData(Object.keys(requestParams).length !== 0 ? requestParams : params))

    } else {
      params = {
        ...params,
        page: -1,
      }
      dispatch(reportActions.getReportDataChart(Object.keys(requestParams).length !== 0 ? requestParams : params))
    }
  }

  function handleChangePage(_event, page) {
    let newPage = page + 1;
    let params;

    if (splittedPathname[splittedPathname.length - 1] !== 'colaborator-access-by-month') {
      params = {
        ...data,
        page: newPage,
        limit: paging.limit,
      };
    } else {
      params = {
        ...dataColaboratorAccessMonth,
        page: newPage,
        limit: paging.limit,
      };
    }

    dispatch(reportActions.getReportData(Object.keys(requestParams).length !== 0 ? { ...requestParams, page: newPage, limit: paging.limit } : params))
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;

    const params = {
      ...data,
      page: 1,
      limit: limit,
    }

    dispatch(reportActions.getReportData(Object.keys(requestParams).length !== 0 ? { ...requestParams, page: 1, limit: limit } : params))
  }

  function handleSelectedColumnChecked(columnName) {
    return filters.properties.filter(({ value }) => value === columnName)[0]?.checked;
  }

  return {
    // report redux
    report,
    filters,
    paging,
    fieldValidation,
    loading,
    tab,
    // businessUnitProps redux
    local,
    occupationArea,
    businessUnit,
    // others
    stepFlux,
    splittedPathname,
    checked,
    handleFilter,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelectedColumnChecked
  }
}