import React, { useState } from 'react';
import { Card, makeStyles, CardContent, Button, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { articleActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ImagineDialogConfirmation } from '../ImagineTemplates';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1),
    color: theme.palette.neutrals.low.medium,
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  containerButton: {
    marginTop: 30,
  },
}));

function ArticleProgressCard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const {
    articleDetails,
    newArticle
  } = useSelector((state) => state.articlesReducer);

  function handleLoadingButton() {
    setLoading(!loading);
  }

  function handleDelete(id) {
    dispatch(articleActions.deleteArticle(id, t('seu_artigo_foi_excluido')));
  }

  function sendArticle() {
    if (props.isEdit) {
      dispatch(articleActions.putArticle(articleDetails, t('seu_artigo_foi_salvo')));
    } else {
      dispatch(articleActions.createArticle(newArticle, t('seu_artigo_foi_criado')));
    }
  }

  return (
    <Card>
      <ImagineDialogConfirmation></ImagineDialogConfirmation>
      <CardContent>
        <Box className={classes.containerButton}>
          <Button
            style={{ width: '100%' }}
            variant="contained"
            color="primary"
            onClick={() => { handleLoadingButton(); sendArticle(); }}
          >
            {props.isEdit ? t('salvar_artigo') : t('publicar_artigo')}
          </Button>

          {props.isEdit && <Button
            style={{ width: '100%', marginTop: 10 }}
            variant="outlined"
            color="primary"
            onClick={() => handleDelete(id)}
          >
            {t('excluir_artigo')}
          </Button>}
        </Box>
      </CardContent>
    </Card>
  );
}

export { ArticleProgressCard };
